<template>

    <!-- ============================ Page Title Start================================== -->
    <div class="page-title  bl-dark" >
        <div class="container">

        </div>
    </div>
    <!-- ============================ Page Title End ================================== -->

    <!-- ============================ Main Section Start ================================== -->
    <section class="gray-bg">
        <div class="container-fluid">
            <div class="row">
                <!-- Item Wrap Start -->
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="row" v-if="!loadJobList">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="_filt_tag786">
                                <base-spinner class="mx-3" ></base-spinner>
                                Loading .....
                            </div>
                        </div>
                    </div>
                    <div class="row" v-else>
                        <div class="col-lg-12 col-md-12 col-sm-12 ml-3">
                            <!-- Filter Search -->
                            <div class="_filt_tag786">
                                <div class="form-group filter_group m-0">
                                    <div class="_tag780">Similar jobs to current job</div>
                                </div>
                                <div class="_tag785">
                                    <div class="_g78juy mx-1 d-none d-lg-block" v-if="filterSort">
                                            <select class="form-control ">
                                                <option>Custom config</option>
                                                <option>Most relevant</option>
                                                <option>Most recent</option>
                                            </select>
                                    </div>
                                    <div class="_g78juy mx-1 d-none d-lg-block" v-if="filterSort">
                                            <select class="form-control">
                                                <option>Relevance</option>
                                                <option>Most relevant</option>
                                                <option>Most recent</option>
                                            </select>
                                    </div>
                                    <div class="_g78juy mx-1 d-none d-lg-block" v-if="filterSort">
                                            <select class="form-control">
                                                <option>Salary</option>
                                                <option>Most relevant</option>
                                                <option>Most recent</option>
                                            </select>
                                    </div>
                                    <div class="_g78juy mx-1 d-none d-lg-block" v-if="filterSort">
                                            <select class="form-control" >
                                                <option>Sector</option>
                                                <option>Most relevant</option>
                                                <option>Most recent</option>
                                            </select>
                                    </div>
                                    <div class="_g78juy mx-1 d-none d-lg-block" v-if="filterSort">
                                            <select class="form-control">
                                                <option>Location</option>
                                                <option>Most relevant</option>
                                                <option>Most recent</option>
                                            </select>
                                    </div>
                                    <span v-if="!filterSort">
                                        <span  @click="gotoNextJob('prevs')"  href="javascript:void(0);" class="eltio_buttons cursor-pointer">
                                            <i class="mr-2 bi " :class="exceedMinJobList?' bi-stop-circle':'bi-chevron-double-left'"></i> prevs
                                        </span>
                                        <span  @click="gotoNextJob('next')" href="javascript:void(0);"  class="eltio_buttons cursor-pointer">
                                            next  <i class="ml-2 bi bi-chevron-double-right" :class="exceedMaxJobList?' bi-stop-circle':'bi-chevron-double-right'"></i>
                                        </span>
                                    </span>
                                    <span @click="filterSort = !filterSort" class="eltio_buttons_dark cursor-pointer">
                                        <i class="bi " :class="filterSort?'bi-arrow-left-right':'bi-funnel'"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row ">
                        <div class="col-lg-4 col-md-12 col-sm-12 overflow-auto" style="height:120vh">

                            <!-- Single Job List -->
                            <div class="col-12"  v-for="(job, index) in compFeaturedJobOffers" :key="job.id">
                                <div class="_jb_list72" style="background:#f5f5f5">
                                    <div class="jobs-like bookmark">
                                        <label class="toggler toggler-danger">
                                            <input type="checkbox">
                                                <i class="text-primary bi tiny-font" :class="myBookmarks.includes(job.id)?'bi-heart-fill':'bi-heart'">
                                                    {{ myBookmarks.includes(job.id)?'saved':'' }}
                                                </i>
                                        </label>
                                    </div>
                                    <div class="_jb_list72_flex">
                                        <div class="_jb_list72_first">
                                            <div class="_jb_list72_yhumb small-thumb">
                                                <img src="https://via.placeholder.com/100x100" class="img-fluid" alt="">
                                            </div>
                                        </div>
                                        <div class="_jb_list72_last">
                                            <div class="_times_jb">{{ job.username.substr(0,100) }}</div>
                                            <h4 class="_jb_title">
                                                <a href="javascript:void(0)" @click="updateJobDetails(job.id, index)" >
                                                    {{ job.title.substr(0,65) }}
                                                    {{ job.title.length > 55 ? '....':''}}
                                                </a>
                                            </h4>
                                            <div class="_times_jb">{{ JSON.parse(job.job_location)['country'] }}, {{ JSON.parse(job.job_location)['city'] }}</div>
                                            <ul class="_requirement_lists">
                                                <li>
                                                    <span class="_elios req_1 pl-0 text-primary">
                                                        {{ job.currency == 'n'?'&#8358;':'' }}
                                                        {{ job.currency == '$'?'$':'' }}
                                                        {{ job.currency == '£'?'£':'' }}
                                                        {{ job.currency == 'c'?'&#8373;':'' }}
                                                        {{ job.currency == 'e'?'&#8364;':'' }}
                                                        <i class="bi bi-credit-card-2-front pr-1" v-if="job.salary_status == 'none' ||
                                                            job.salary_status == 'request_salary' "></i>
                                                        {{ job.salary_status == "none" ||
                                                           job.salary_status == "request_salary" ?
                                                           'salary n/a ':job.salary.toLocaleString("en") + ' ' +  placeholderOptions[job.job_type]
                                                        }}
                                                    </span>
                                                </li>
                                                <li>
                                                    <span class="_elios req_1 pl-0 text-primary">
                                                        <i class="bi bi-stack pr-1"></i>
                                                        {{ job.job_type.replace('_','-') }}
                                                    </span>
                                                </li>
                                                <li>
                                                    <span class="_elios req_1 pl-0 text-warning">
                                                        <i class="bi bi-clock pr-1"></i>
                                                     {{ new Date(job.end_date).toLocaleDateString("en-US") }}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="_jb_list72_foot">
                                        <div class="_times_jb"></div>
                                    </div>
                                </div>
                            </div>

                            <base-pagination>
                            </base-pagination>

                        </div>

                        <div class="col-lg-8 col-md-12 col-sm-12">

                            <job-details :jobContent="currentJobDetails"
                                         :key="currentKey"
                                         :applicationHistory="myApplications"
                                         :bookmarkHistory="myBookmarks"
                                         @emit-update-job-history="updateJobHistory"
                                         v-if="currentJobDetails.length > 0" >
                            </job-details>

                        </div>


                    </div>



                </div>

            </div>
        </div>
    </section>
    <!-- ============================ Main Section End ================================== -->


</template>

<script>
// @ is an alias to /src
import { ref,inject,computed } from 'vue';
import { useStore } from 'vuex';
import JobDetails from '@/components/job/JobDetails.vue';

export default {
  name: "JobDirectDetails",
  props:{
      jobLinkId:{
          type:String,
          default:"none"
      },
      jobLinkTitle:{
          type:String,
          default:"none"
      },
  },
  components: {
      JobDetails
  },
  setup(props){
      console.log(props.jobLinkTitle)
      console.log(props.jobLinkId)
      const axios = inject('axios');
      const coreUrl = inject('coreUrl');

      const store = useStore();

      // get the current status of client account
      const isAuthenticated = ref(store.getters["auth/getAuthAuthentication"].isAuthenticated);

      const contractType = {
          full_time:"bg-success",
          part_time:"bg-primary",
          consultancy:"bg-info",
          freelance:"bg-secondary"
      }

      const loadJobList = ref(false);
      const featuredJobOffers = ref([]);
      const myApplications = ref([]);
      const myBookmarks = ref([]);
      const currentJobDetails = ref([]);

      const params = { par_total: 10000 , order_type: 'desc' }
      // use axios to get the data
      axios.get(
          coreUrl.backendUrl+'get_simple_job_ad_list',
          {withCredentials: true , params: params }).then((response) =>{

          const result = response.data.data.job_ads;

          // use the dispatch function to call an action that calls a mutation
          // to set the data of for the job ad
          featuredJobOffers.value = result

          if(featuredJobOffers.value){
              loadJobList.value = true
              currentJobDetails.value = featuredJobOffers.value.filter((item) => item.id === props.jobLinkId)
          }

          myApplications.value = response.data.data.applications?JSON.parse(response.data.data.applications):[]
          myBookmarks.value = response.data.data.bookmarks?JSON.parse(response.data.data.bookmarks):[]


      }).catch((error)=>{
              console.log(error)
      })


      const currentJobIndex = ref(0);
      const currentKey = ref(props.jobLinkId);


      function updateJobDetails(id, index){

          currentJobIndex.value = index;
          currentKey.value = id;
          currentJobDetails.value = featuredJobOffers.value.filter((item) => item.id === id);
          // console.log(currentJobDetails.value[0].title)
          window.history.replaceState("", "", `/job_details/${currentJobDetails.value[0].title.replace(/[^a-zA-Z0-9]/g,'_')}/${currentJobDetails.value[0].id}`);

      }

      const compFeaturedJobOffers = computed(function(){

          let finalFeaturedJobOffers = []

          finalFeaturedJobOffers = featuredJobOffers.value.filter(function(item){
              if( myApplications.value.includes(item.id) || currentKey.value == item.id ){
                  return false;
              }

              return true;
          }
        )

          return finalFeaturedJobOffers;

      })


      const exceedMaxJobList = ref(false);
      const exceedMinJobList = ref(false);
      function gotoNextJob(direction){
          if(direction == 'next')
          {
            let newIndex = +currentJobIndex.value + 1;
            let jobListLength =  compFeaturedJobOffers.value.length;
             if( newIndex < jobListLength ) {
                 console.log(newIndex)
                 console.log(jobListLength)
                 exceedMaxJobList.value = false;
                 exceedMinJobList.value = false;
                 let job_id = compFeaturedJobOffers.value[newIndex]['id']
                 currentJobDetails.value = featuredJobOffers.value.filter((item) => item.id === job_id);
                 currentJobIndex.value = newIndex;
                 currentKey.value = job_id;
             }else{
                 exceedMaxJobList.value = true;
             }
          }


          if(direction == 'prevs')
          {
            let newIndex = +currentJobIndex.value - 1;
            let jobListLength = -1;
             if( newIndex > jobListLength ) {
                 console.log(newIndex)
                 console.log(jobListLength)
                 exceedMaxJobList.value = false;
                 exceedMinJobList.value = false;
                 let job_id = compFeaturedJobOffers.value[newIndex]['id']
                 currentJobDetails.value = featuredJobOffers.value.filter((item) => item.id === job_id);
                 currentJobIndex.value = newIndex;
                 currentKey.value = job_id;
             }else{
                 exceedMinJobList.value = true;
             }
          }

      }

      const filterSort = ref(false);

      function updateJobHistory(history, data){
          if(history == 'applications'){
              myApplications.value = data
          }

          if(history == 'bookmarks'){
              myBookmarks.value = data
          }

      }


      const placeholderOptions = {
          full_time:"salary per annum",
          part_time:"salary per annum",
          consultancy:"consultancy fee",
          freelance:"freelance fee",
          internship:"salary per annum",
          contract:"contract fee",
          volunteer:"volunteer fee"
      }



      return {
          coreUrl,
          contractType,
          isAuthenticated,
          loadJobList,
          featuredJobOffers,
          compFeaturedJobOffers,
          currentJobDetails,
          currentKey,
          gotoNextJob,
          filterSort,
          exceedMaxJobList,
          exceedMinJobList,
          updateJobDetails,
          updateJobHistory,
          myApplications,
          myBookmarks,
          placeholderOptions
      }


  }
};
</script>

<style scoped>

.employer-brands ul li{
    list-style-type:none;
    display:inline;
}

</style>
